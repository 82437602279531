export const environment = {
  production: false,
  apiUrl: import.meta.env?.API_URL || 'https://sid-stg-be.ideatolife.me',
  demo: false,
  uat: false,
  development: true,
  local: false,
  environment: 'dev',
  env: {
    NG_APP_ENV: import.meta.env && import.meta.env['NG_APP_ENV'],
  },
};
