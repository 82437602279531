export const LANDING_PATH = '/';

// User Account & Auth Routes
export const AUTH_PATH = 'auth';
export const DASHBOARD_PATH = 'dashboard';
export const LOGIN_BASE_LINK_PATH = 'login';
export const REGISTER_BASE_LINK_PATH = 'register';
// export const USER_LISTING = `user-management`;
export const ADMIN_USER_LISTING = `admin-users`;
export const APP_USER_LISTING = `app-users`;


export const SALES = `sales`;
export const PROFILE = `profile`;
export const ORDERS = `${SALES}/orders`;
export const RETURNS = `${SALES}/returns`;
export const PAYMENTS_METHODS = `${SALES}/payment-methods`;
export const DELIVERY_METHODS = `${SALES}/delivery-methods`;
export const CATALOG = `catalog`;
export const PRODUCTS = `${CATALOG}/products`;
export const CATEGORIES = `${CATALOG}/categories`;
export const PAGES = `Pages`;
export const SYSTEM_SETTINGS = `system-settings`;



export const RESET_PASSWORD_BASE_LINK_PATH = 'reset-password';
export const FORGOT_PASSWORD_BASE_LINK_PATH = 'forgot-password';
export const FORGOT_PASSWORD_OTP_CONFIRMATION = 'forgot-password-otp-confirmation';
export const LOGIN_PATH = `${AUTH_PATH}/${LOGIN_BASE_LINK_PATH}`;
export const REGISTER_PATH = `${AUTH_PATH}/${REGISTER_BASE_LINK_PATH}`;
export const RESET_PASSWORD_PATH = `/${AUTH_PATH}/${RESET_PASSWORD_BASE_LINK_PATH}`;
export const FORGOT_PASSWORD_PATH = `${AUTH_PATH}/${FORGOT_PASSWORD_BASE_LINK_PATH}`;

// Pages start
export const TERMS_AND_PRIVACY_BASE_LINK_PATH = 'term-and-privacy';
export const TERMS_AND_PRIVACY_PATH = `/${TERMS_AND_PRIVACY_BASE_LINK_PATH}`;
export const REFUND_AND_RETURN_POLICY_BASE_LINK_PATH = 'refund-and-return-policy';
export const REFUND_AND_RETURN_POLICY_PATH = `/${REFUND_AND_RETURN_POLICY_BASE_LINK_PATH}`;
export const SHIPPING_AND_DELIVERY_BASE_LINK_PATH = 'shipping-and-delivery';
export const SHIPPING_AND_DELIVERY_PATH = `/${SHIPPING_AND_DELIVERY_BASE_LINK_PATH}`;
export const FAQ_BASE_LINK_PATH = 'faq';
export const FAQ_PATH = `/${FAQ_BASE_LINK_PATH}`;
// Pages end

export const ADD_PATH = 'add';
export const EDIT_PATH = ':id';

